/* Timesheet Component Styles */

.timesheet-container {
  width: 100%;
  max-width: 2xl;
  margin: 0 auto;
  padding: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #666666;
}

.timesheet-paper {
  padding: 1.5rem;
}

/* Week Info Card */
.week-info-card {
  margin-bottom: 1.5rem;
}

/* Alert Messages */
.alert-message {
  margin-bottom: 1rem;
}

/* Day Cards */
.day-card {
  height: 100%;
  margin-bottom: 1rem;
}

.day-header {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.day-divider {
  margin: 0.75rem 0;
}

/* Entries Container */
.entries-container {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.entry-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #f0f0f0;
}

.entry-item:last-child {
  border-bottom: none;
}

/* Offline Chip */
.offline-chip {
  font-size: 0.7rem;
  height: 20px;
}

/* Responsive Adjustments */
@media (max-width: 640px) {
  .timesheet-container {
    padding: 1rem;
  }
  
  .timesheet-paper {
    padding: 1rem;
  }
  
  .entries-container {
    max-height: 150px;
  }
}
