.geolocation-button {
    background-color: white;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .geolocation-button:hover {
    background-color: #f4f4f4;
  }
  
  .material-icons {
    font-size: 18px;
    color: #333;
  }
