/* TimeTrack Component Styles */

/* Break Schedule Table */
.break-schedule-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.break-schedule-header {
  display: flex;
  font-weight: 600;
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.break-schedule-row {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  transition: background-color 0.2s ease;
}

.break-schedule-row:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.break-schedule-cell {
  flex: 1;
  padding: 12px 16px;
  display: flex;
  align-items: center;
}

.break-schedule-cell-content {
  width: 100%;
}

/* Time Entries List */
.time-entries {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 8px;
}

.section-header-container {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  padding: 8px 0;
}

/* Date Navigation */
.date-navigation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.date-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-button {
  width: 36px;
  height: 36px;
}

.today-button {
  min-width: 100px;
}

/* Status Indicators */
.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.status-clocked-in {
  background-color: #8CC63F;
}

.status-clocked-out {
  background-color: #FF1D25;
}

.status-on-break {
  background-color: #FBB03B;
}

/* Connection Status */
.connection-status {
  display: flex;
  align-items: center;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .break-schedule-cell {
    padding: 8px;
    font-size: 0.875rem;
  }
  
  .time-entries {
    max-height: 300px;
  }
}
