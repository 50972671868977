.dialogAppBar {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #00416e !important;
}

.dialogContent {
  flex-grow: 1;
  padding: 24px;
  overflow-y: auto;
}

.dialogFooter {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
}

.doneButton {
  min-width: 100px;
  background-color: #00416e !important;
}

.doneButton:hover {
  background-color: #003157 !important;
}

.tabPanel {
  padding: 24px;
}
